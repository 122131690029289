<template>
  <div class="row">
    <!-- Table Container Card -->
    <WorkcenterList class="col-sm-2" @clicked="onClickWorkcenter" />

    <b-card class="col-lg-10 col-sm-10" :hidden="!wcID > 0">
      <div class="m-2">
        <b-row>
          <b-col
            cols="4"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>
          <b-col cols="6" lg="2">
            <date-filter />
          </b-col>
          <b-col cols="6" lg="2">
            <vue-excel-xlsx
              :data="row"
              :columns="excelFields"
              :file-name="'Breakdown' + '_' + new Date()"
              :file-type="'xlsx'"
              :sheet-name="'Page1'"
              class="btn btn-primary"
            >
              {{ $t("Export to Excel") }}
            </vue-excel-xlsx>
          </b-col>
          <b-col cols="6" lg="2">
            <button
              b-button
              v-b-modal.modal-breakdonwReasonCustom
              size="sm"
              class="btn btn-success"
              @click="addManualBreakdownClick()"
            >
              {{ $t("Add Manual Breakdown") }}
            </button>
          </b-col>
        </b-row>
      </div>
      <!-- :disabled="(datefilterId >= 3 && datefilterId != 10) || appRole == 'PLANT_MANAGER'" -->

      <b-table
        ref="refBreakdownListTable"
        class="position-relative"
        :items="row"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :current-page="currentPage"
        :per-page="perPage"
        :filter="search"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >
        <template #cell(startDate)="data">
          {{ format_date(data.item.startDate) }}
        </template>
        <template #cell(endDate)="data">
          {{ format_date(data.item.endDate) }}
        </template>
        <template #cell(action)="data">
          <b-button
            v-b-modal.modal-breakdonwReason
            size="sm"
            class="btn btn-success"
            @click="sendInfo(data)"
          >
            {{ $t("Set Breakdown") }}
          </b-button>
        </template>
        <template #head()="data">
          <span>{{ $t(data.label) }}</span>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted"
              >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
              {{ dataMeta.of }} entries</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalBreakdowns"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
      <b-modal
        id="modal-breakdonwReasonCustom"
        cancel-variant="outline-secondary"
        ok-title="Ok"
        :cancel-title="$t('Close')"
        centered
        :title="$t('Add Manual Breakdown')"
        size="lg"
        no-close-on-backdrop
        @ok="addBreakdown"
      >
        <b-form ref="form">
          <b-form-group :label="$t('Breakdown Reason')" label-for="BreakdownId">
            <v-select
              id="BreakdownId"
              v-model="selectedBreakdown"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="dataCollectionBreakdowns"
              label="name"
              placeholder="Select"
              :state="breakdownState"
            />
          </b-form-group>
          <b-form-group :label="$t('Start Date')">
            <flat-pickr
              id="breakdownStartDate"
              v-model="startDate"
              class="form-control"
              :config="{ enableTime: true, dateFormat: 'Y-m-d H:i' }"
              :placeholder="$t('Start Date')"
            />
          </b-form-group>
          <b-form-group :label="$t('End Date')">
            <flat-pickr
              id="targetFinishDate"
              v-model="endDate"
              class="form-control"
              :config="{ enableTime: true, dateFormat: 'Y-m-d H:i' }"
              :placeholder="$t('End Date')"
            />
          </b-form-group>
          <b-form-group :label="$t('Additional Info')">
            <b-form-input
              id="additionalInfo"
              v-model="additionalInfo"
              class="form-control-merge"
              :placeholder="$t('Additional Info')"
            />
          </b-form-group>
          <b-form-group>
            <b-col md="12" class="demo-inline-spacing">
              <b-form-radio
                v-model="isPlanedBreakdown"
                plain
                name="some-radios3"
                value="false"
              >
                {{ $t("UnPlanned") }}
              </b-form-radio>
              <b-form-radio
                v-model="isPlanedBreakdown"
                plain
                name="some-radios3"
                value="true"
              >
                {{ $t("Planned") }}
              </b-form-radio>
            </b-col>
          </b-form-group>
        </b-form>
      </b-modal>
      <b-modal
        id="modal-breakdonwReason"
        cancel-variant="outline-secondary"
        :ok-title="$t('Set Breakdown')"
        :cancel-title="$t('Close')"
        centered
        :title="$t('Set breakdown reason')"
        size="lg"
        no-close-on-backdrop
        @ok="addBreakdownReason"
      >
        <b-form>
          <b-form-group :label="$t('Breakdown Reason')" label-for="BreakdownId">
            <v-select
              id="BreakdownId"
              v-model="selectedBreakdown"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="dataCollectionBreakdowns"
              label="name"
              placeholder="Select"
            />
          </b-form-group>
          <b-form-group :label="$t('Start Date')">
            <flat-pickr
              id="breakdownStartDate"
              v-model="startDate"
              class="form-control"
              :config="{ enableTime: true, dateFormat: 'Y-m-d H:i' }"
              :placeholder="$t('Start Date')"
            />
          </b-form-group>
          <b-form-group :label="$t('End Date')">
            <flat-pickr
              id="targetFinishDate"
              v-model="endDate"
              class="form-control"
              :config="{ enableTime: true, dateFormat: 'Y-m-d H:i' }"
              :placeholder="$t('End Date')"
            />
          </b-form-group>
          <b-col md="12" class="demo-inline-spacing">
            <b-form-radio
              v-model="isPlanedBreakdown"
              plain
              name="some-radios3"
              value="false"
            >
              {{ $t("UnPlanned") }}
            </b-form-radio>
            <b-form-radio
              v-model="isPlanedBreakdown"
              plain
              name="some-radios3"
              value="true"
            >
              {{ $t("Planned") }}
            </b-form-radio>
          </b-col>
        </b-form>
      </b-modal>
    </b-card>

    <b-card class="col-lg-10 col-sm-10" :hidden="wcID > 0">
      <h4 style="margin-bottom: 20px">{{ $t("Select Workcenter") }}</h4>
    </b-card>
  </div>
</template>


<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BModal,
  VBModal,
  BButton,
  BForm,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BFormGroup,
  BFormRadio,
  BFormInvalidFeedback,
} from "bootstrap-vue";
import vSelect from "vue-select";
import store from "@/store";
import {
  inject,
  toRefs,
  watch,
  ref,
  onUnmounted,
  computed,
} from "@vue/composition-api";
import { avatarText } from "@core/utils/filter";
import moment from "moment";
import Ripple from "vue-ripple-directive";
import useBreakdownList from "./useBreakdownList";
import breakdownStoreModule from "../breakdownStoreModule";
import WorkcenterList from "../workcenter-list/WorkcenterList.vue";
import DateFilter from "../components/DateFilter.vue";
import flatPickr from "vue-flatpickr-component";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { useToast } from "vue-toastification/composition";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import formValidation from "@core/comp-functions/forms/form-validation";

export default {
  directives: {
    Ripple,
    "b-modal": VBModal,
  },
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BModal,
    VBModal,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    WorkcenterList,
    DateFilter,
    BForm,
    BTable,
    BFormGroup,
    BFormRadio,
    flatPickr,
    BFormInvalidFeedback,
    ValidationObserver,
    ValidationProvider,
  },
  data() {
    return {
      id: 0,
      workCenterID: store.getters["pamis-breakdown-workcenter/GetWorkCenterId"],
      selectedBreakdown: {},
      search: "",
      isPlanedBreakdown: false,
      startDate: null,
      endDate: null,
      additionalInfo: "",
      breakdownState: null,
    };
  },
  computed: {},
  watch: {
    datefilterId(value) {
      this.GetBreakdownDatas(this.workCenterID, value);
    },
  },

  methods: {
    format_date(value) {
      if (value) {
        const myDate = new Date("0001-01-01T00:00:00Z");
        const dateItem = moment(value);

        if (dateItem > myDate) {
          return moment(value).locale("en").format("LLL");
        }
      }
    },
    onClickWorkcenter(value) {
      this.workCenterID = value.id;
      this.GetBreakdownDatas(this.workCenterID, this.datefilterId);
    },

    addBreakdownReason() {
      const data = {};
      data.id = this.id;
      data.breakdownID = this.selectedBreakdown.id;
      data.isPlanned =
        this.isPlanedBreakdown == "true" || this.isPlanedBreakdown == true;
      let sDate = new Date(this.startDate);
      sDate.setTime(sDate.getTime() - sDate.getTimezoneOffset() * 60000);
      data.startDate = sDate.toISOString();
      let eDate = new Date(this.endDate);
      eDate.setTime(eDate.getTime() - eDate.getTimezoneOffset() * 60000);
      data.endDate = eDate.toISOString();
      store
        .dispatch("pamis-breakdown/UpdateBreakdownData", data)
        .then((response) => {
          if (response) {
            let wcID = computed(
              () => store.getters["pamis-breakdown/GetWorkCenterId"]
            );
            let datefilterId = computed(
              () => store.getters["pamis-breakdown/GetDateFilterId"]
            );
            this.GetBreakdownDatas(wcID.value, datefilterId.value);
          }
        });
    },

    addBreakdown() {
      this.workCenterID =
        store.getters["pamis-breakdown-workcenter/GetWorkCenterId"];
      if (this.workCenterID <= 0 || !this.workCenterID) {
        this.toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: `Can't add breakdown. Please select a workcenter first`,
            icon: "AlertCircleIcon",
            variant: "danger",
          },
        });
        return;
      }
      if (!this.startDate || !this.endDate || !this.selectedBreakdown.id) {
        this.toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: `Please make sure to fill all necessary fields (*)`,
            icon: "AlertCircleIcon",
            variant: "danger",
          },
        });
        return;
      }

      const data = {};
      data.breakdownID = this.selectedBreakdown.id;
      let sDate = new Date(this.startDate);
      sDate.setTime(sDate.getTime() - sDate.getTimezoneOffset() * 60000);
      data.startDate = sDate.toISOString();
      let eDate = new Date(this.endDate);
      eDate.setTime(eDate.getTime() - eDate.getTimezoneOffset() * 60000);
      data.endDate = eDate.toISOString();
      data.additionalInfo = this.additionalInfo;
      data.workCenterID = this.workCenterID;
      data.isPlanned =
        this.isPlanedBreakdown == "true" || this.isPlanedBreakdown == true;
      data.isActive = true;

      store
        .dispatch("pamis-breakdown/CreateBreakdownData", data)
        .then((response) => {
          if (response) {
            let wcID = computed(
              () => store.getters["pamis-breakdown/GetWorkCenterId"]
            );
            let datefilterId = computed(
              () => store.getters["pamis-breakdown/GetDateFilterId"]
            );
            this.GetBreakdownDatas(wcID.value, datefilterId.value);
          }
        });
      this.toast({
        component: ToastificationContent,
        position: "top-right",
        props: {
          title: `Process Successful`,
          icon: "BellIcon",
          variant: "success",
        },
      });

      this.selectedBreakdown = {};
      this.additionalInfo = "";
      this.startDate = null;
      this.endDate = null;
    },
    sendInfo(data) {
      this.id = data.item.id;
      this.selectedBreakdown = {
        id: data.item.breakdownID,
        name: data.item.breakdownName,
      };
      this.isPlanedBreakdown = data.item.isPlanned;
      this.startDate = data.item.startDate;
      this.endDate = data.item.endDate;
    },
    addManualBreakdownClick() {
      this.selectedBreakdown = {};
      this.isPlanedBreakdown = false;
      this.startDate = null;
      this.endDate = null;
      this.additionalInfo = null;
    },
  },

  setup() {
    const PAMIS_MODULE_NAME = "pamis-breakdown";

    // Register module
    if (!store.hasModule(PAMIS_MODULE_NAME))
      store.registerModule(PAMIS_MODULE_NAME, breakdownStoreModule);

    //const userData = JSON.parse(localStorage.getItem("userData"));
    const appRole = "PLANT_MANAGER";
    //(userData ? userData.roles[0] : 'PLANT_MANAGER')
    const wcID = computed(
      () => store.getters["pamis-breakdown/GetWorkCenterId"]
    );

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(PAMIS_MODULE_NAME))
        store.unregisterModule(PAMIS_MODULE_NAME);
    });
    const toast = useToast();

    const datefilterId = computed(
      () => store.getters["pamis-breakdown/GetDateFilterId"]
    );

    const resetBreakdownData = () => {};
    const { refFormObserver, getValidationState, resetForm } = formValidation(
      resetBreakdownData()
    );

    const {
      row,
      tableColumns,
      perPage,
      currentPage,
      totalBreakdowns,
      dataMeta,
      perPageOptions,
      sortBy,
      isSortDirDesc,
      refBreakdownListTable,
      refetchData,
      search,
      tableExcelColumns,
      dataCollectionBreakdowns,
      GetBreakdownDatas,
    } = useBreakdownList();
    const excelFields = tableExcelColumns.map((item) => ({
      label: item.key.toUpperCase(),
      field: item.key,
    }));

    if (
      wcID.value != null &&
      wcID.value > 0 &&
      datefilterId.value != null &&
      datefilterId.value > 0
    ) {
      GetBreakdownDatas(wcID.value, datefilterId.value);
    }

    return {
      row,
      tableColumns,
      perPage,
      currentPage,
      totalBreakdowns,
      dataMeta,
      perPageOptions,
      sortBy,
      isSortDirDesc,
      refBreakdownListTable,
      refetchData,
      search,
      // Filter
      avatarText,
      GetBreakdownDatas,
      datefilterId,
      dataCollectionBreakdowns,
      excelFields,
      wcID,
      appRole,
      toast,
      refFormObserver,
      getValidationState,
      resetForm,
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
