<template>
  <div>
    <b-row>
      <b-col>
        <b-form>
          <b-row>
            <b-col lg="8" md="10" sm="10" cols="12">
              <!-- <v-select
                @change="onDateSelectChange"
                style="margin-top: 10px; width: 100%"
                v-model="selectedDateFormat"
                :options="selectOptions"
              ></v-select> -->

              <b-form-select
                id="breakdown-date-select"
                @change="onDateSelectChange"
                style="margin-top: 10px; width: 100%"
                v-model="selectedDateFormat"
                :options="selectOptions"
              >
              </b-form-select>
            </b-col>
          </b-row>
        </b-form>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { ref, computed } from "@vue/composition-api";
import flatPickr from "vue-flatpickr-component";
import i18n from "@/libs/i18n";
import {
  BButton,
  BRow,
  BCol,
  BForm,
  BFormSelect,
  BFormGroup,
  BFormInput,
  BBadge,
} from "bootstrap-vue";
import { VueSelect } from "vue-select";
import store from "@/store";
export default {
  name: "DateFilter",
  components: {
    BButton,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    VueSelect,
    BFormSelect,
    BRow,
    flatPickr,
    BBadge,
  },
  setup() {
    const selectedDateFormat = ref(
      store.getters["pamis-breakdown/GetDateFilterId"]
    );

    store.dispatch("pamis-breakdown/GetDateFilters");
    const selectOptions = computed(() => {
      const dateFilters = store.getters["pamis-breakdown/GetDateFilters"];
      dateFilters?.forEach((e) => {
        e.text = i18n.t(e.text);
      });
      return dateFilters;
    });

    function onDateSelectChange(param) {
      store.dispatch("pamis-breakdown/SetDateFilterId", param);
    }
    return {
      selectOptions,
      selectedDateFormat,
      onDateSelectChange,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>

<style scoped>
.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.3s ease-out;
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}
</style>
