import axios from '@axios'
import ApiService from "@/services/pamisDashboardApi.service"

const baseURL= process.env.VUE_APP_API_URL+'/pamis/pda/'
//const baseURL = 'https://api.eldor.com.tr/pamis/pda/'
//to do burasıda general servisler gibi olacak.

export default {
  namespaced: true,
  state: {
    dateFilters: null,
    dateFilterId: 2,
    workCenterId: 0,
  },
  getters: {
    GetDateFilters(state) {
      return state.dateFilters
    },
    GetDateFilterId(state) {
      return state.dateFilterId
    },
    GetWorkCenterId(state){
      return state.workCenterId
    }
  },
  mutations: {
    SetDateFilters(state, filters) {
      state.dateFilters = filters
    },
    SetDateFilterId(state, newDateFilterId) {
      state.dateFilterId = newDateFilterId
    },
    SetWorkCenterId(state, newWorkCenterId){
      state.workCenterId = newWorkCenterId
    }
  },

  actions: {
    fetchBreakdowns(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${baseURL}Breakdown/WorkCenter/GetBreakdown?workCenterID=${data.workCenterID}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchWorkCenters(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${baseURL}Organization/GetWorkCentersBySiteId?siteId=100`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchBreakdownData(ctx, data) {
      return new Promise((resolve, reject) => {
        ctx.commit("SetWorkCenterId", data.workCenterID);
        axios
          .get(`${baseURL}Breakdown/WorkCenter/GetBreakdownData?workcenterId=${data.workCenterID}&dateFilterId=${data.dateFilterId}`)
          .then((response) =>{ 
            resolve(response)})
          .catch(error => reject(error))
      })
    },
    UpdateBreakdownData(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .put(`${baseURL}Breakdown/UpdateBreakdownData`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    CreateBreakdownData(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${baseURL}Breakdown/CreateBreakdownData`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    GetDateFilters(ctx) {
      return new Promise((resolve, reject) => {
        ApiService.get("Utility/Site", "GetDateFilters")
          .then((response) => {
            ctx.commit("SetDateFilters", response.data);
            resolve(response.data);
          })
          .catch((error) => {
            ctx.commit("SetDateFilters", null);
            resolve(error);
          });
      });
    },
    SetDateFilterId(ctx, newId) {
      ctx.commit("SetDateFilterId", newId);
    },
  },
}
